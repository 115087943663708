var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"7","lg":"7"}},[_c('v-toolbar',{staticClass:"mt-2",attrs:{"dense":"","flat":""}},[_c('platform-selector'),_c('v-autocomplete',{staticClass:"mx-1",attrs:{"items":_vm.ownerList,"label":"Owner"},model:{value:(_vm.options.owner),callback:function ($$v) {_vm.$set(_vm.options, "owner", $$v)},expression:"options.owner"}}),_c('v-select',{staticClass:"mx-1",attrs:{"items":[
            { value: null, text: 'All' },
            { value: true, text: 'Yes' },
            { value: false, text: 'No' } ],"label":"Enabled"},model:{value:(_vm.options.enabled),callback:function ($$v) {_vm.$set(_vm.options, "enabled", $$v)},expression:"options.enabled"}}),_c('v-select',{staticClass:"mx-1",attrs:{"items":_vm.socialTypes,"label":"Type"},model:{value:(_vm.options.socialType),callback:function ($$v) {_vm.$set(_vm.options, "socialType", $$v)},expression:"options.socialType"}}),_c('v-select',{staticClass:"mx-1",attrs:{"items":_vm.feedStatus,"label":"Status"},model:{value:(_vm.options.status),callback:function ($$v) {_vm.$set(_vm.options, "status", $$v)},expression:"options.status"}}),_c('v-spacer')],1)],1),_c('v-col',{attrs:{"cols":"12","xl":"5","lg":"5"}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-text-field',{attrs:{"append-outer-icon":_vm.search ? 'mdi-send' : '',"label":"search","single-line":"","hide-details":"","clearable":"","append-icon":"mdi-magnify"},on:{"click:append-outer":_vm.updateSearch,"click:clear":_vm.clearSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.feeds,"options":_vm.options,"server-items-length":_vm.totalFeeds,"loading":_vm.loading,"height":_vm.tableHeight,"fixed-header":"","show-expand":"","dense":"","must-sort":"","footer-props":{
      itemsPerPageOptions: [5, 10, 20, 50],
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.ownerId",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(_vm.wallsignTenants[item.ownerId]))]}},{key:"item.lastUpdate",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(item.lastUpdate)))]}},{key:"item.socialType",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(_vm._f("socialType")(item.socialType)))]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(_vm._f("feedStatus")(item.status)))]}},{key:"item.enabled",fn:function(ref){
    var item = ref.item;
return [(item.enabled)?_c('div',[_c('v-icon',[_vm._v("mdi-check")])],1):_vm._e()]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [(item.loadingDownload)?_c('v-progress-circular',{staticClass:"mr-2",attrs:{"size":"24","width":"3","color":"grey","indeterminate":""}}):_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.startDownload(item)}}},[_vm._v("mdi-download")]),_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","to":'/social/' + item.id + '/messages'}},[_c('v-icon',[_vm._v("mdi-format-list-bulleted-square")])],1)]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('p',{staticClass:"mt-2"},[_vm._v("Id: "+_vm._s(item.id))]),(item.messageStatus)?_c('p',[_vm._v("Error: "+_vm._s(item.messageStatus))]):_vm._e()])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }