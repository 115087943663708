import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import Vue from "vue";
import store from "@/store";
export default {
  getList: function getList(options) {
    var url = "".concat(store.getters.socialUrl, "/api/Feeds/?pageNumber=").concat(options.pageNumber); //build url with params

    if (options.pageSize) {
      url += "&pageSize=".concat(options.pageSize);
    }

    if (options.search) {
      url += "&search=".concat(options.search);
    }

    if (options.owner) {
      url += "&ownerId=".concat(options.owner);
    }

    if (options.socialType !== null) {
      url += "&socialType=".concat(options.socialType);
    }

    if (options.status !== null) {
      url += "&status=".concat(options.status);
    }

    if (options.enabled !== null) {
      url += "&enabled=".concat(options.enabled);
    }

    if (options.sortBy) {
      url += "&sortBy=".concat(options.sortBy);
    }

    if (options.sortDescending) {
      url += "&sortDescending=".concat(options.sortDescending);
    }

    console.log(url);
    return Vue.prototype.$axios.get(url, {
      headers: {
        "x-functions-key": store.getters.socialKey
      }
    });
  },
  getMessageList: function getMessageList(id, options) {
    var url = "".concat(store.getters.socialUrl, "/api/Messages/").concat(id, "?pageNumber=").concat(options.pageNumber);

    if (options.pageSize) {
      url += "&pageSize=".concat(options.pageSize);
    }

    if (options.search) {
      url += "&search=".concat(options.search);
    }

    return Vue.prototype.$axios.get(url, {
      headers: {
        "x-functions-key": store.getters.socialKey
      }
    });
  },
  startDownload: function startDownload(id, type) {
    return Vue.prototype.$axios.post("".concat(store.getters.socialUrl, "/api/").concat(type, "/").concat(id), null, {
      headers: {
        "x-functions-key": store.getters.socialKey
      }
    });
  },
  getFeed: function getFeed(id) {
    return Vue.prototype.$axios.get("".concat(store.getters.socialUrl, "/api/Feeds/").concat(id), {
      headers: {
        "x-functions-key": store.getters.socialKey
      }
    });
  },
  getInfo: function getInfo(platformId) {
    var platform = store.state.platforms.find(function (p) {
      return p.id === platformId;
    });

    if (!platform) {
      return null;
    }

    return Vue.prototype.$axios.get("".concat(platform.socialUrl, "/api/Info?json=true"), {
      headers: {
        "x-functions-key": platform.socialKey
      }
    });
  }
};