<template>
  <div style="width: 100%">
    <v-row>
      <v-col cols="12" xl="7" lg="7">
        <v-toolbar dense flat class="mt-2">
          <platform-selector></platform-selector>
          <v-autocomplete
            :items="ownerList"
            v-model="options.owner"
            label="Owner"
            class="mx-1"
          ></v-autocomplete>
          <v-select
            :items="[
              { value: null, text: 'All' },
              { value: true, text: 'Yes' },
              { value: false, text: 'No' },
            ]"
            v-model="options.enabled"
            label="Enabled"
            class="mx-1"
          ></v-select>
          <v-select
            :items="socialTypes"
            v-model="options.socialType"
            label="Type"
            class="mx-1"
          ></v-select>
          <v-select
            :items="feedStatus"
            v-model="options.status"
            label="Status"
            class="mx-1"
          ></v-select>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-col>
      <v-col cols="12" xl="5" lg="5">
        <v-toolbar dense flat>
          <v-text-field
            v-model="search"
            :append-outer-icon="search ? 'mdi-send' : ''"
            label="search"
            single-line
            hide-details
            clearable
            append-icon="mdi-magnify"
            @click:append-outer="updateSearch"
            @click:clear="clearSearch"
          ></v-text-field>
        </v-toolbar>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="feeds"
      :options.sync="options"
      :server-items-length="totalFeeds"
      :loading="loading"
      :height="tableHeight"
      class="elevation-1"
      fixed-header
      show-expand
      dense
      must-sort
      v-resize="onResize"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 20, 50],
      }"
    >
      <template v-slot:[`item.ownerId`]="{ item }">{{
        wallsignTenants[item.ownerId]
      }}</template>
      <template v-slot:[`item.lastUpdate`]="{ item }">{{
        item.lastUpdate | date
      }}</template>
      <template v-slot:[`item.socialType`]="{ item }">{{
        item.socialType | socialType
      }}</template>
      <template v-slot:[`item.status`]="{ item }">{{
        item.status | feedStatus
      }}</template>
      <template v-slot:[`item.enabled`]="{ item }" ]>
        <div v-if="item.enabled">
          <v-icon>mdi-check</v-icon>
        </div>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-progress-circular
          size="24"
          width="3"
          class="mr-2"
          color="grey"
          indeterminate
          v-if="item.loadingDownload"
        ></v-progress-circular>
        <v-icon class="mr-2" @click="startDownload(item)" v-else
          >mdi-download</v-icon
        >
        <v-btn icon class="mr-2" :to="'/social/' + item.id + '/messages'">
          <v-icon>mdi-format-list-bulleted-square</v-icon>
        </v-btn>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <p class="mt-2">Id: {{ item.id }}</p>
          <p v-if="item.messageStatus">Error: {{ item.messageStatus }}</p>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import tableHeightMixin from "@/mixins/tableHeightMixin";
import SocialService from "@/services/SocialService";
import costants from "@/utils/costants";

export default {
  name: "Social",
  mixins: [tableHeightMixin],
  components: {
    PlatformSelector: () => import("@/components/PlatformSelector"),
  },
  data: () => ({
    totalFeeds: 0,
    feeds: [],
    loading: true,
    downloader: {},
    options: {
      itemsPerPage: 20,
      page: 1,
      socialType: null,
      status: null,
      owner: null,
      enabled: null,
    },
    dialog: {
      //
    },
    search: null,
    filters: {
      //
    },
  }),
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    platformId: function () {
      this.getDataFromApi();
    },
  },
  computed: {
    ...mapState({
      wallsignTenants: (state) => state.wallsignTenants,
      platformId: (state) => state.currentPlatformId,
    }),
    ...mapGetters({
      ownerList: "ownerList",
    }),
    feedStatus: function () {
      return [{ value: null, text: "All" }, ...costants.FeedStatus];
    },
    socialTypes: function () {
      return [{ value: null, text: "All" }, ...costants.SocialTypes];
    },
    headers: function () {
      return [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Owner",
          value: "ownerId",
          sortable: false,
        },
        {
          text: "Enabled",
          value: "enabled",
          sortable: false,
        },
        {
          text: "Last update",
          value: "lastUpdate",
        },
        {
          text: "Type",
          value: "socialType",
          sortable: false,
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
        },
        {
          text: "Messages",
          value: "downloadedItems",
          sortable: false,
        },
        {
          text: "Actions",
          value: "action",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    updateSearch: function () {
      this.getDataFromApi();
    },
    clearSearch: function () {
      this.search = null;
      this.getDataFromApi();
    },
    getDataFromApi: function () {
      this.loading = true;
      SocialService.getList({
        pageNumber: this.options.page,
        pageSize: this.options.itemsPerPage,
        sortBy: this.options.sortBy[0],
        sortDescending: this.options.sortDesc[0],
        search: this.search,
        enabled: this.options.enabled,
        owner: this.options.owner,
        socialType: this.options.socialType,
        status: this.options.status,
      })
        .then((result) => {
          this.feeds = result.data.data;
          this.totalFeeds = result.data.totalRecords;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    startDownload: function (item) {
      var feed = this.feeds.find((f) => f.id === item.id);
      if (feed) {
        this.$set(feed, "loadingDownload", true);

        SocialService.startDownload(
          feed.id,
          costants.SocialTypes[feed.socialType].text
        ).then((result) => {
          this.$set(
            feed,
            "resultChecker",
            setTimeout(() => {
              this.checkDownloadResult(feed.id, result.data.statusQueryGetUri);
            })
          );
        });
      }
    },
    checkDownloadResult: function (id, url) {
      this.$axios.get(url).then((result) => {
        var feed = this.feeds.find((f) => f.id === id);
        if (feed) {
          switch (result.data.runtimeStatus) {
            case "Completed":
              this.$set(feed, "loadingDownload", false);
              this.$set(feed, "resultChecker", null);
              this.$notify({
                type: "success",
                text: "Download started correctly!",
              });
              SocialService.getFeed(id).then((feedResult) => {
                var index = this.feeds.findIndex((f) => f.id === id);
                if (index >= 0) {
                  this.feeds.splice(index, 1, feedResult.data);
                  switch (feedResult.data.status) {
                    case 1:
                      this.$notify({
                        type: "success",
                        text: `Feed ${feedResult.data.name} downloaded correctly!`,
                      });
                      break;
                    case 3:
                      this.$notify({
                        type: "error",
                        text: `Feed ${feedResult.data.name} returned and error during download!`,
                      });
                      break;
                  }
                }
              });

              break;

            case "Pending":
            case "Running":
              this.$set(
                feed,
                "resultChecker",
                setTimeout(() => {
                  this.checkDownloadResult(id, url);
                })
              );
              break;

            case "Canceled":
            case "Failed":
            case "Terminated":
            case "Unknown":
              this.$set(feed, "loadingDownload", false);

              this.$notify({
                type: "error",
                text: `An error occurred, result from server was: ${result.data.runtimeStatus}\nTask Id: ${result.data.id}`,
              });
              break;
          }
        }
      });
    },
  },
};
</script>
